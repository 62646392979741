const elms = document.getElementsByClassName( 'splide' );


for ( var i = 0; i < elms.length; i++ ) {

    if(elms[ i ].classList.contains('slider-partner')){
        new Splide( elms[ i ],{
            type   : 'loop',
            arrows: false,
            autoplay: true,
            gap: '3rem',
            perPage: 3,
            
            breakpoints: {
                1023: {
                    perPage: 2,
                },
                767: {
                    perPage: 1,
                },
            }
        } ).mount();
    }else{
        new Splide( elms[ i ],{
            type   : 'loop',
            arrows: false,
            autoplay: true,
        } ).mount();
    }
}